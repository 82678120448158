import { Component, OnInit } from '@angular/core';
import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as emailjs from '@emailjs/browser';
import {ReCaptchaV3Service} from 'ng-recaptcha';


@Component({
  selector: 'app-contact',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  faLocationPin = faLocationPin;
  faEmail = faMailBulk;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  contactForm: FormGroup;
  emailError: boolean;
  emailSent: boolean;
  url: string;

  constructor(private fb: FormBuilder, private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: new FormControl ('', [Validators.required, Validators.minLength(2), Validators.pattern(/^[^ ][\w\W ]*[^ ]/)]),
      email: new FormControl ('',  [Validators.required, Validators.email, Validators.pattern(/^[^ ][\w\W ]*[^ ]/)]),
      subject: new FormControl ('', [Validators.required, Validators.minLength(5), Validators.pattern(/^[^ ][\w\W ]*[^ ]/)]),
      message: new FormControl ('',  [Validators.required, Validators.minLength(20), Validators.pattern(/^[^ ][\w\W ]*[^ ]/)])
    });
    this.emailError = false;
    this.emailSent = false;
  }

  get name() { return this.contactForm.get('name'); }

  get email() { return this.contactForm.get('email'); }

  get subject() { return this.contactForm.get('subject'); }

  get message() { return this.contactForm.get('message'); }



}
