import {Component, OnInit} from '@angular/core';
import {Loader} from '@googlemaps/js-api-loader';
import {MapService} from '../shared/services/map.service';
import {Marker} from '../shared/model/marker';
import {MarkerClusterer} from '@googlemaps/markerclusterer';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalTapListComponent} from '../modal-tap-list/modal-tap-list.component';
import {faBeer} from '@fortawesome/free-solid-svg-icons';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  markers: Marker[];
  markersMap2021 = [];
  markersMap2023 = [];
  markersMap2024 = [];
  markersMap2025 = [];
  markerClusterer2021;
  markerClusterer2023;
  markerClusterer2024;
  markerClusterer2025;
  selectedInfoWindow;
  id: number;
  today = new Date();
  public routerLinkVariable = this.id;
  faBeer = faBeer;
  styles: Record<string, google.maps.MapTypeStyle[]> = {
    default: [],
    silver: [
      {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#dadada' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#c9c9c9' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }],
      },
    ],

    night: [
      { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
      },
      {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
      },
    ],

    retro: [
      { elementType: 'geometry', stylers: [{ color: '#ebe3cd' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#523735' }] },
      { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f1e6' }] },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#c9b2a6' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#dcd2be' }],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#ae9e90' }],
      },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#93817c' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [{ color: '#a5b076' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#447530' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#f5f1e6' }],
      },
      {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ color: '#fdfcf8' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#f8c967' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#e9bc62' }],
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{ color: '#e98d58' }],
      },
      {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#db8555' }],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#806b63' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#8f7d77' }],
      },
      {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#ebe3cd' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#dfd2ae' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ color: '#b9d3c2' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#92998d' }],
      },
    ],

    hiding: [
      {
        featureType: 'poi.business',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  };

  constructor(private mapService: MapService, public modalService: NgbModal) {  }

  ngOnInit() {
    let loader = new Loader({
      apiKey: 'AIzaSyBtg5RzEOSMhhU4oDAlBo25HMHxLHFzVcQ'
    });
    let initialLocation;
    const PAESTUM_BOUNDS = {
      north: 40.68,
      south: 40.34,
      west: 14.79,
      east: 15.21,
    };
    loader.load().then(() => {
      let map = new google.maps.Map(document.getElementById('map'),{
        fullscreenControl: false,
        zoom: 13,
        mapTypeControl: false,
        restriction: {
          latLngBounds: PAESTUM_BOUNDS,
          strictBounds: false,
        },
      });


      // Add a style-selector control to the map.
      const styleControl = document.getElementById(
        'style-selector-control'
      ) as HTMLElement;

      map.controls[google.maps.ControlPosition.TOP_LEFT].push(styleControl);

      // Set the map's style to the initial value of the selector.
      const styleSelector = document.getElementById(
        'style-selector'
      ) as HTMLSelectElement;

      map.setOptions({ styles: this.styles[styleSelector.value] });

      // Apply new JSON when the user selects a different style.
      styleSelector.addEventListener('change', () => {
        map.setOptions({ styles: this.styles[styleSelector.value] });
      });
/*      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            initialLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            map.setCenter(initialLocation);
          },
          () => {
            this.setDefaultMapLocation(map);
          });
      } else {*/
      this.setDefaultMapLocation(map);
      this.addMarkers(map);
    });
  }
  setDefaultMapLocation(map) {
    map.setCenter(new google.maps.LatLng(40.42306414968691, 15.00671404164759));
  }
  check2021(event) {
    for (let i = 0 ; i < this.markersMap2021.length; i++) {
      const marker = this.markersMap2021[i];
      if (event.target.checked) {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }
    }
    this.markerClusterer2021.clearMarkers();
    this.markerClusterer2021.addMarkers(this.markersMap2021);
  }
  check2023(event) {
    for (let i = 0 ; i < this.markersMap2023.length; i++) {
      const marker = this.markersMap2023[i];
       if (event.target.checked) {
         marker.setVisible(true);
       } else {
         marker.setVisible(false);
       }
    }
    this.markerClusterer2023.clearMarkers();
    this.markerClusterer2023.addMarkers(this.markersMap2023);
  }

  check2024(event) {
    for (let i = 0 ; i < this.markersMap2024.length; i++) {
      const marker = this.markersMap2024[i];
      if (event.target.checked) {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }
    }
    this.markerClusterer2024.clearMarkers();
    this.markerClusterer2024.addMarkers(this.markersMap2024);
  }

  check2025(event) {
    for (let i = 0 ; i < this.markersMap2025.length; i++) {
      const marker = this.markersMap2025[i];
        if (event.target.checked) {
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
    }
    this.markerClusterer2025.clearMarkers();
    this.markerClusterer2025.addMarkers(this.markersMap2025);
  }

  addMarkers(map) {
    let markers= [];
    let width;
    let height;
    this.mapService.getMarkers().subscribe(res => {
      this.markers = Object.keys(res).map(e => res[e]);
      // @ts-ignore
      for (let i = 0 ; i < this.markers[0].length; i++) {
        const marker = this.markers[0][i];
        const dal = new Date(marker.dal);
        const al = new Date(marker.al);

        width = 50;
        height = 60;
        marker.icon = '../assets/images/turtle-pin.png';

        if (marker.categoria !== 'Evento' || (marker.categoria === 'Evento' && this.today.getTime() <= al.getTime())) {
          const icon = {
            url: marker.icon,
            scaledSize: new google.maps.Size(width, height),
          };
          const m = new google.maps.Marker({
            position: new google.maps.LatLng(marker.lat, marker.lon),
            map,
            title: marker.title,
            icon
          });
          const infowindow = new google.maps.InfoWindow({
            content: this.getInfoWindowContent(marker),
          });

          m.addListener('click', () => {
            if (this.selectedInfoWindow != null && this.selectedInfoWindow.getMap() != null) {
              this.selectedInfoWindow.close();
              if (this.selectedInfoWindow === infowindow) {
                listenerHandle.remove();
                this.selectedInfoWindow = null;
                return;
              }
            }
            this.selectedInfoWindow = infowindow;

            infowindow.open({
              anchor: m,
              map,
              shouldFocus: false,
            });
          });
          let clickableItem;
          let tabs;
          const listenerHandle = google.maps.event.addListener(infowindow, 'domready', () => {
            clickableItem = document.getElementById('taplist' + marker.id);
            // @ts-ignore
            const id = document.getElementById('marker' + marker.id).value;
            if (clickableItem && !clickableItem.hasAttribute('listenerOnClick')) {
              clickableItem.addEventListener('click', () => {
                this.open(id);
              });
              clickableItem.setAttribute('listenerOnClick', 'true');
            }

            // manage tabs in infowindow
            tabs = document.querySelectorAll('.nav-tabs a');
            tabs.forEach(function(tab) {
              tab.addEventListener('click', function(event) {
                event.preventDefault();
                const activeTab = this.getAttribute('href');
                document.querySelector('.nav-tabs li.active').classList.remove('active');
                this.parentNode.classList.add('active');
                document.querySelector('.tab-content .tab-pane.active').classList.remove('active');
                document.querySelector(activeTab).classList.add('active');
/*                document.querySelector(this.getAttribute('href')).classList.add('active');
                this.parentElement.classList.add('active');*/
              });
            });
          });
          if (marker.year === 2021) {
            this.markersMap2021.push(m);
          }
          if (marker.year === 2023) {
            this.markersMap2023.push(m);
          }
          if (marker.year === 2024) {
            this.markersMap2024.push(m);
          }
          if (marker.year === 2025) {
            this.markersMap2025.push(m);
          }

          m.setMap(map);
        }
      }
      this.markerClusterer2021 = new MarkerClusterer({ map });
      this.markerClusterer2021.addMarkers(this.markersMap2021);

      this.markerClusterer2023 = new MarkerClusterer({ map });
      this.markerClusterer2023.addMarkers(this.markersMap2023);

      this.markerClusterer2024 = new MarkerClusterer({ map });
      this.markerClusterer2024.addMarkers(this.markersMap2024);

      this.markerClusterer2025 = new MarkerClusterer({ map });
      this.markerClusterer2025.addMarkers(this.markersMap2025);
    });
  }


  getInfoWindowContent(marker: Marker): string {
    const description = marker.description ? '<p><b>' + marker.description + '</b></p>' : '';
    const website = marker.link ? '<p>Website: <a target="_blank" href="' + marker.link + '">' + marker.link + '</a></p>' : '';
    let taplist;

      taplist = '<div style="position: relative; text-align: center; margin: 2px 0 2px 0">' +
        '<a href="#" id="taplist' + marker.id + '">' +
        '<h4 style="position: absolute; color:#e5b622; font-weight: bold; text-shadow: -1px 0 #173243, 0 1px #173243, 1px 0 #173243, 0 -1px #173243; width:100%; height: 0%; top: 0; bottom: 20px; margin: auto">TAP LIST</h4>' +
        '<img id="taplist" style="width: 105px; height: 110px;" class="media-object" src="./assets/images/taplist.png" alt="icon taplist">' +
        ' </a></div>';


    const contentString =
      '<div class="container" id="content">' +
      '<div class="row firstHeading"><h3 id="firstHeading" >' +  marker.title.toUpperCase() + '</h3></div>' +
      '<div id="bodyContent">' +
      'Nido del ' + marker.year +
      '<p><b><a target="_blank" href="' + marker.gmapLink + '">' + marker.indirizzo + '</a></b></p>' +
       description +
      // taplist +
       website +
      '<input type="hidden" id="marker' + marker.id + '" name="markerId" value="' + marker.id + '" />' +
      '</div>' +
      '</div>';

/*    if (marker.eventTitle && al !== '') {
      const event =
        '<div class="container" id="content">' +
        '<div class="row" style="padding-top: 8px;"><h3 id="firstHeading" >' + marker.eventTitle + '</h3></div>' +
        '<div id="bodyContent">' +
        eventDates +
        '<p><b><a target="_blank" href="' + marker.gmapLink + '">' + marker.indirizzo + '</a></b></p>' +
        '<p><b>' + marker.eventPrice + '</b></p>' +
        '<p style="max-width: 300px;"><b>' + marker.eventDescription + '</b></p>' +
        '</div>' +
        '</div>';

      const contentTabs = '<div class="container">' +
        '<ul class="nav nav-tabs">' +
        '<li class="nav-item active" "><a class="nav-link" style="font-size: 15px;color: #173243;" href="#tab1" data-toggle="tab"><b>Locale</b></a></li>' +
        '<li class="nav-item"><a class="nav-link" style="font-size: 15px;color: #173243;" href="#tab2" data-toggle="tab"><b>Evento</b></a></li>' +
        '</ul>' +
        '<div class="tab-content">' +
        '<div class="tab-pane active" id="tab1">' + contentString + '</div>' +
        '<div class="tab-pane" id="tab2">' + event + '</div>' +
        '</div>' +
        '</div>';

      contentString = contentTabs;
    }*/

    return contentString;
  }

  open(id: number) {
    this.id = id;
    const modalRef = this.modalService.open(ModalTapListComponent);
    modalRef.componentInstance.id = this.id;
  }

}
